
function Footer() {
    return (
        <footer style={{ textAlign: 'center', maxWidth: '100%' ,padding: '20px', backgroundColor: '#f1f1f1' }}>
            <p>
                Të citohet: Islami, B., Ibrahimi, K., Ibrahimi, H., Grapci-Kotori, L., Bilalli, A., Musliu, M., Gashi., A &amp; Geci, D. (2024). Fauna of Kosovo. Version 1.
            </p>
        </footer>
    );
}

export default Footer;

